<script>
import { mapState } from "vuex";
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Matriz from "@/components/widgets/network-matriz";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    Matriz,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      loading: false,
      matriz: null,
    };
  },
  methods: {
    getMatriz() {
      this.loading = true;

      api.get("network/matriz").then((response) => {
        if (response.data.status == 'success') {
          this.matriz = response.data.matriz;
        } else {
          this.matriz = null;
        }

        this.loading = false;
      });
    },
  },
  mounted() {
    this.getMatriz();
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('Matriz') }}</h3>
        <p></p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <Matriz :data="matriz"></Matriz>
      </div>
    </div>
  </Layout>
</template>
